import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { Icon } from '../../../common/icon'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage19() {
  const question = {
    title: 'Frage 19 von 35',
    headline: '',
    subline: 'Mit meinem Ausbildungs- und Weiterbildungslevel bin ich...',
  }

  const answers = [
    {
      name: 'Aus-und-Weiterbildungsmoeglichkeiten',
      id: 'unzufrieden',
      value:
        '... derzeit unzufrieden und möchte mehr gefordert und gefördert werden.',
      desc: '',
    },
    {
      name: 'Aus-und-Weiterbildungsmoeglichkeiten',
      id: 'zufrieden',
      value: '... soweit zufrieden, würde aber gern noch dazu lernen.',
      desc: '',
    },
    {
      name: 'Aus-und-Weiterbildungsmoeglichkeiten',
      id: 'total-zufrieden',
      value: '... total zufrieden, mehr brauche ich nicht.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-purple">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="purple"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="turkis"
              index={19}
            />
          </div>
        </section>

        <MatchingNavigation
          color="purple"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-20`}
          prev={`${AREA_CARE_PATH}/spielen/frage-18`}
          index={19}
        />

        <Icon position="3" name="books" />
      </main>
    </>
  )
}
